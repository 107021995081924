import moment from 'moment';
import List from '../../presentational/List';
import { TouchableOpacity } from 'react-native';
import Text from '../../presentational/Text';

export default function ReportList({
  onPress,
  data,
  includeDate,
  includeSerialNumber,
  dateField,
}) {
  const labels = ['Tool', 'Description'];
  let listData = [];
  if (includeDate) {
    labels.push('Due Date');
    listData = data.map((item) => {
      const name = `${item.partNumber} (${item.serialNumber})`;
      return [
        <TouchableOpacity onPress={() => onPress(item)}>
          <Text style={{ textDecoration: 'underline' }}>{name}</Text>
        </TouchableOpacity>,
        item.partDescription || item.nomenclature,
        moment.utc(item[dateField]).format('MM/DD/YYYY'),
      ];
    });
  } else {
    listData = data.map((item) => {
      let name = `${item.partNumber}`;
      if (includeSerialNumber) name += ` (${item.serialNumber})`;
      return [
        <TouchableOpacity
          onPress={() => {
            if (includeSerialNumber) {
              onPress(item);
            }
          }}
        >
          <Text style={{ textDecoration: 'underline' }}>{name}</Text>
        </TouchableOpacity>,
        item.partDescription || item.nomenclature,
      ];
    });
  }

  return <List tableHead={labels} tableRows={listData} />;
}
