import { useStoreActions, useStoreState } from 'easy-peasy';
import Button from '../../presentational/Button';
import CustomModal from '../../presentational/Modal';
import moment from 'moment';
import { View } from 'react-native';
import Text from '../../presentational/Text';
import Gap from '../../presentational/Gap';
import Col from '../../presentational/Col';
import Row from '../../presentational/Row';
import { pxToDp, vhToDp } from '../../../utilities/general';
import Image from '../../presentational/Image';

function Field({ title, value }) {
  return (
    <View
      style={{
        borderBottomWidth: 0.1,
        bottomBorderWidth: 0.1,
        borderColor: 'lightgray',
        flexDirection: 'row',
        justifyContent: 'space-between',
        padding: 10,
      }}
    >
      <Text
        style={{
          fontSize: 20,
          color: '#ab2328',
        }}
      >
        {title}:
      </Text>

      <Text
        style={{
          fontSize: 20,
          marginLeft: 15,
          fontFamily: 'Barlow_500Medium_Italic',
          color:
            value == 'Available'
              ? 'green'
              : value === 'Inspection'
                ? '#c0b000'
                : '#00205b',
        }}
      >
        {value}
      </Text>
    </View>
  );
}

export default function PreCheckWPWarningModal() {
  const {
    tools: { searchResults },
    workPackage: { preCheckInfoWPModal, selectedWPPreCheck },
    document,
    status: { placeholder },
  } = useStoreState((state) => state);

  const {
    getInspectionQuestionsThunk,
    setShowInspectionQuestionsModalAction,
    setPreCheckInfoWPModalAction,
    setPrecheckWorkPackageToolModalVisibleAction,
  } = useStoreActions((actions) => actions);

  return (
    <CustomModal
      style={{ width: 1000 }}
      width='80%'
      height='50%'
      title='Pre-Check Warning'
      onClose={() => setPreCheckInfoWPModalAction(false)}
      isVisible={preCheckInfoWPModal}
    >
      <Row>
        <Col xs={12} lg={6}>
          <Field
            title='Serial Number'
            value={selectedWPPreCheck.serialnumber}
          />
          <Field
            title='Current Tool Status'
            value={selectedWPPreCheck.statusdescription}
          />
          <Field
            title='Current Tool User'
            value={selectedWPPreCheck.tooluser}
          />
          <Field
            title='Storage Location'
            value={selectedWPPreCheck.storagelocation}
          />
          {selectedWPPreCheck.lastinspectionDate && (
            <Field
              title='Last Inspection Date'
              value={
                selectedWPPreCheck &&
                selectedWPPreCheck.lastinspectionDate &&
                moment(selectedWPPreCheck.lastinspectionDate).isValid()
                  ? moment(selectedWPPreCheck.lastinspectionDate).format(
                      'MM/DD/YYYY'
                    )
                  : 'Not Set'
              }
            />
          )}
          <Field
            title='Next Calibration Test Due Date'
            value={
              selectedWPPreCheck?.nextCalibrationTestDueDate?.includes(
                '1900-01-01'
              )
                ? ''
                : selectedWPPreCheck?.nextCalibrationTestDueDate
                  ? moment(
                      selectedWPPreCheck?.nextCalibrationTestDueDate
                    )?.format('MM/DD/YYYY')
                  : ''
            }
          />
          <Field
            title='Inspection Day Threshold'
            value={selectedWPPreCheck.inspectionnumofdaysThreshold}
          />
          <Field
            title='Number of Days Between Inspections'
            value={selectedWPPreCheck.daysbetweenInspections}
          />
          <Field
            title='Number of Checkouts'
            value={selectedWPPreCheck.numofCheckouts}
          />
          <Field
            title='Number of Checkouts Until Inspection'
            value={selectedWPPreCheck.numofcheckoutstillnextinspection}
          />
          <Field
            title='Inspection Required'
            value={selectedWPPreCheck.inspectionRequired}
          />
        </Col>
        <Col xs={12} lg={6}>
          <Image
            style={{
              marginBottom: pxToDp(15),
              border: '1px solid black',
            }}
            height={vhToDp(25)}
            uri={document.body !== '' ? document.body : placeholder.body}
          />
          {selectedWPPreCheck &&
            selectedWPPreCheck.inspectionRequired == 'Y' && (
              <Button
                fullWidth
                onPress={() => {
                  getInspectionQuestionsThunk(
                    searchResults.find(
                      (tool) => tool.id == selectedWPPreCheck.toolid
                    ).inspectionTypes
                  );
                  setPreCheckInfoWPModalAction(false);
                  setPrecheckWorkPackageToolModalVisibleAction(false);
                  setShowInspectionQuestionsModalAction(true);
                }}
              >
                Complete Inspection
              </Button>
            )}
        </Col>
      </Row>

      <Gap />
    </CustomModal>
  );
}
