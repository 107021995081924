import moment from 'moment';

import React, { useRef } from 'react';
import {
  StyleSheet,
  ScrollView,
  TouchableOpacity,
  Platform,
  View,
  ActivityIndicator,
} from 'react-native';
import { AntDesign, Ionicons } from '@expo/vector-icons';
import styled from 'styled-components';
import { useStoreActions, useStoreState } from 'easy-peasy';
import { Divider } from '@rneui/themed';
import * as DocumentPicker from 'expo-document-picker';

import Card from '../../presentational/Card';
import Input from '../../presentational/Input';
import Button from '../Button';
import Text from '../../presentational/Text';

import Gap from '../../presentational/Gap';
import Switch from '../../presentational/Switch';
import List from '../List';
import CustomModal from '../Modal';

import { remToDp, vhToDp, vwToDp } from '../../../utilities/general';
import Col from '../../presentational/Col';
import Row from '../../presentational/Row';
import Icon from '../../presentational/Icon';
import { Camera } from 'expo-camera';
import RemoveToolImageButton from '../Button/RemoveToolImageButton';
import Image from '../../presentational/Image';
import Carousel, { Pagination } from 'react-native-reanimated-carousel';
import { useSharedValue } from 'react-native-reanimated';

const ColSwitch = styled(Col)`
  padding-left: ${remToDp(1.5)}px;
`;

const EditToolStepForm = ({ ...props }) => {
  const progress = useSharedValue(0);
  const ref = useRef(null);
  const openScanner = async () => {
    const { status } = await Camera.requestCameraPermissionsAsync();
    setCameraPermissionAction(status === 'granted');
    setScannerOpenAction(true);
  };

  const {
    tenant,
    tools: {
      input,
      nextCalibrationCalendarVisible,
      nextPMICalendarVisible,
      isEditable,
      editStepCount,
      reservationInput,
      reservedTools,
      loadTestDatePickerActive,
    },
    status: { placeholder },
    storageLocation,
    toolStatus: { searchResults },
    location,
    auth,
    inspections,
    documentLoading,
    documentsToAdd,
  } = useStoreState((state) => state);

  const {
    setToolsInputAction,
    setNextCalibrationCalendarVisible,
    setNextPMICalendarVisible,
    upsertToolsThunk,
    setExtraDocumentAction,
    upsertExtraDocumentThunk,
    setSelectedToolInspectionTypesAction,
    setShowInspectionHistoryModal,
    setReservationInputAction,
    setReservationCalendarVisible,
    reserveSingleToolThunk,
    setCameraPermissionAction,
    setScannerOpenAction,
    setLoadTestDatePickerActiveAction,
    getInspectionQuestionsThunk,
    setShowInspectionQuestionsModalAction,
    getToolInspectionHistoryThunk,
    setAlertThunk,
    setEditStepCountAction,
    setInspectionsShowModalAction,
    setInspectionsInputAction,
    setInspectionsEditAction,
    removeInspectionsThunk,
    getToolHistoryThunk,
    setTransferModalAction,
    setToolTransferInputAction,
  } = useStoreActions((actions) => actions);

  const handleNextStep = () => {
    setEditStepCountAction(editStepCount + 1);
  };

  const handlePreviousStep = () => {
    setEditStepCountAction(editStepCount - 1);
  };

  const handleKeyPress = ({ nativeEvent }) => {
    if (nativeEvent.key === 'Tab') {
      setEditStepCountAction(editStepCount + 1);
    }
  };

  const onPressPagination = (index) => {
    ref.current?.scrollTo({
      count: index - progress.value,
      animated: true,
    });
  };

  const renderFormStep = () => {
    switch (editStepCount) {
      case 1:
        return (
          <>
            <Row>
              <Col xs={12} lg={6}>
                <Row>
                  <Col>
                    <Input
                      placeholder='Type in Part No.'
                      label='Part No.'
                      value={input.partNumber}
                      onChangeText={async (partNumber) =>
                        setToolsInputAction({ partNumber })
                      }
                      editable={isEditable}
                      required
                    />
                  </Col>
                  <Col>
                    <Input
                      placeholder='Type in Nomenclature'
                      label='Nomenclature'
                      value={input.nomenclature}
                      onChangeText={(nomenclature) =>
                        setToolsInputAction({ nomenclature })
                      }
                      editable={isEditable}
                      required
                    />
                  </Col>
                  <Col>
                    <Input
                      placeholder='Type in Serial No.'
                      label='Serial No.'
                      value={input.serialNumber}
                      onChangeText={(serialNumber) =>
                        setToolsInputAction({ serialNumber })
                      }
                      editable={isEditable}
                      required
                      onKeyPress={handleKeyPress}
                    />
                  </Col>
                </Row>
              </Col>
              <Col xs={12} lg={6}>
                <Row>
                  <Col xs={12} md={12} style={{ alignItems: 'center' }}>
                    {!documentLoading && (
                      <>
                        <Carousel
                          ref={ref}
                          width={vwToDp(20)}
                          height={vhToDp(33)}
                          style={{ border: '1px solid black' }}
                          data={
                            documentsToAdd.length === 0
                              ? [placeholder]
                              : documentsToAdd
                          }
                          onProgressChange={progress}
                          renderItem={({ item }) => (
                            <Image
                              width={vwToDp(20)}
                              height={vhToDp(33)}
                              uri={item?.body}
                            />
                          )}
                        ></Carousel>
                        <Pagination.Basic
                          progress={progress}
                          data={documentsToAdd}
                          dotStyle={{
                            backgroundColor: 'rgba(0,0,0,0.2)',
                            borderRadius: 50,
                          }}
                          containerStyle={{ gap: 5, marginTop: 10 }}
                          onPress={onPressPagination}
                        />
                      </>
                    )}
                    {documentLoading && (
                      <View
                        style={{
                          width: vwToDp(20),
                          height: vhToDp(33),
                          border: '1px solid black',
                          alignItems: 'center',
                          justifyContent: 'center',
                        }}
                      >
                        <ActivityIndicator size='large' />
                      </View>
                    )}
                  </Col>
                </Row>
                <Row>
                  <Col xs={12} md={4}>
                    <Button.UploadToolImage
                      isEdit={!isEditable}
                      setIndex={ref.current?.scrollTo}
                    />
                  </Col>
                  <Col xs={12} md={4}>
                    <RemoveToolImageButton
                      getIndex={ref.current?.getCurrentIndex}
                      isEdit={!isEditable}
                      setIndex={ref.current?.scrollTo}
                    />
                  </Col>
                  <Col xs={12} md={4}>
                    <Button.RotateImage
                      isEdit={!isEditable}
                      getIndex={ref.current?.getCurrentIndex}
                    />
                  </Col>
                </Row>
              </Col>
            </Row>

            <Row>
              <Col xs={12} md={4}>
                <Button.EditTool />
              </Col>
              <Col xs={12} md={4}>
                <Button fullWidth title='Next' onPress={handleNextStep} />
              </Col>
              <Col xs={12} md={4}>
                <Button
                  fullWidth
                  title='Save'
                  disabled={
                    input.partNumber === '' ||
                    input.nomenclature === '' ||
                    input.serialNumber === '' ||
                    !isEditable
                  }
                  onPress={handleSubmit}
                />
              </Col>
            </Row>
          </>
        );
      case 2:
        return (
          <>
            <Row>
              <Col xs={12} md={6}>
                <Input
                  placeholder='Type in Facility Location'
                  label='Facility Location'
                  onSelect={(item) =>
                    setToolsInputAction({
                      tenantLocation: item.id,
                      storageLocationId: '',
                    })
                  }
                  selectedItem={(item) => item.name}
                  rowTextForSelection={(item) => item.name}
                  dropdown
                  dropdownChoices={location.searchResults
                    .sort((a, b) => a.name.localeCompare(b.name))
                    .filter((item) => {
                      return item.active !== false;
                    })}
                  value={
                    location.searchResults.find(
                      (location) => location.id === input.tenantLocation
                    )?.name || ''
                  }
                  editable={isEditable}
                  onKeyPress={handleKeyPress}
                />
              </Col>
              <Col xs={12} md={6}>
                {input.tenantLocation && input.tenantLocation !== '0' && (
                  <Input
                    placeholder='Type in Storage Location'
                    label='Storage Location'
                    value={
                      storageLocation.searchResults.find(
                        (location) => location.id === input.storageLocationId
                      )?.name || ''
                    }
                    onSelect={(storageLocation) =>
                      setToolsInputAction({
                        storageLocationId: storageLocation.id,
                      })
                    }
                    selectedItem={(item) => item.name}
                    rowTextForSelection={(item) => item.name}
                    dropdown
                    dropdownChoices={storageLocation.searchResults
                      .sort((a, b) => a.name.localeCompare(b.name))
                      .filter((item) => {
                        return (
                          item.active !== false &&
                          input.tenantLocation === item.tenantlocationid
                        );
                      })}
                    editable={isEditable}
                  />
                )}
              </Col>
            </Row>
            <Row
              style={{
                justifyContent: 'flex-end',
              }}
            >
              {auth?.user?.role?.toLowerCase() === 'customer admin' ||
                (auth?.user?.role?.toLowerCase() === 'admin' && (
                  <Col xs={12} md={3}>
                    <Button
                      title='Request Transfer'
                      disabled={input?.toolStatusCode === 'IT'}
                      fullWidth
                      onPress={() => {
                        setToolTransferInputAction({
                          ...location.toolTransferInput,
                          fromtoollocationid: input.tenantLocation,
                        });
                        setTransferModalAction(true);
                      }}
                      color={'#00205b'}
                    />
                  </Col>
                ))}
            </Row>
            <Row>
              <Col xs={12} md={3}>
                <Button.EditTool />
              </Col>
              <Col xs={12} md={3}>
                <Button
                  title='Previous'
                  fullWidth
                  onPress={handlePreviousStep}
                />
              </Col>
              <Col xs={12} md={3}>
                <Button fullWidth title='Next' onPress={handleNextStep} />
              </Col>
              <Col xs={12} md={3}>
                <Button
                  fullWidth
                  title='Save'
                  disabled={
                    input.partNumber === '' ||
                    input.nomenclature === '' ||
                    input.serialNumber === '' ||
                    !isEditable
                  }
                  onPress={handleSubmit}
                />
              </Col>
            </Row>
          </>
        );
      case 3:
        return (
          <>
            <Row>
              <Col xs={12} md={3}>
                <Input
                  placeholder='Type in Phase'
                  label='Phase'
                  value={input.phase}
                  onChangeText={(phase) => setToolsInputAction({ phase })}
                  editable={isEditable}
                />
              </Col>
              <Col xs={12} md={3}>
                <Input
                  placeholder='Type in MFG Revision'
                  label='MFG Revision'
                  value={input.currentRevision}
                  onChangeText={(currentRevision) =>
                    setToolsInputAction({ currentRevision })
                  }
                  editable={isEditable}
                />
              </Col>
              <Col xs={12} md={3}>
                <Input
                  placeholder={
                    tenant.input.id === '11' ? 'NSN' : 'Type in F and F Code'
                  }
                  label={tenant.input.id === '11' ? 'NSN' : 'F and F Code'}
                  value={input.fandFCode}
                  onChangeText={(fandFCode) =>
                    setToolsInputAction({ fandFCode })
                  }
                  editable={isEditable}
                />
              </Col>
              <Col xs={12} md={3}>
                <Input
                  placeholder='Type in Batch Numbers'
                  label='Batch Numbers'
                  value={input.CFMId}
                  onChangeText={(CFMId) => setToolsInputAction({ CFMId })}
                  editable={isEditable}
                />
              </Col>
            </Row>
            <Row>
              <Col xs={12} md={3}>
                <Input
                  placeholder='Type in Barcode Scan ID'
                  label='Barcode Scan ID'
                  value={input.barcodescanid}
                  onChangeText={(barcodescanid) =>
                    setToolsInputAction({ barcodescanid })
                  }
                  editable={isEditable}
                  rightIcon={
                    <>
                      {Platform.OS == 'ios' ? (
                        <TouchableOpacity
                          onPress={() => {
                            openScanner();
                          }}
                        >
                          <Icon
                            Component={AntDesign}
                            name='barcode'
                            size={20}
                          />
                        </TouchableOpacity>
                      ) : null}
                    </>
                  }
                />
              </Col>
              <Col xs={12} md={3}>
                <Input
                  placeholder='Type in Manufacturing Case Code'
                  label='Manufacturing Case Code'
                  value={input.manufacturerCageCode}
                  onChangeText={(manufacturerCageCode) =>
                    setToolsInputAction({ manufacturerCageCode })
                  }
                  editable={isEditable}
                />
              </Col>
              <Col xs={12} md={3}>
                <Input
                  placeholder='Type in Qty Required'
                  label='Qty Required'
                  value={input.qtyRequired}
                  onChangeText={(qtyRequired) =>
                    setToolsInputAction({ qtyRequired })
                  }
                  editable={isEditable}
                  number
                />
              </Col>
              <Col xs={12} md={3}>
                <Input
                  placeholder='Type in Multiple Parts'
                  label='Multiple Parts'
                  value={input.multipleParts == false ? 'No' : 'Yes'}
                  onSelect={(item) =>
                    setToolsInputAction({ multipleParts: item.value })
                  }
                  selectedItem={(item) => item.label}
                  rowTextForSelection={(item) => item.label}
                  dropdown
                  dropdownChoices={[
                    { label: 'Yes', value: true },
                    { label: 'No', value: false },
                  ]}
                  editable={isEditable}
                />
              </Col>
            </Row>
            <Col>
              <Input
                placeholder='Type in Notes'
                label='Notes'
                value={input.notes}
                onChangeText={(notes) => {
                  setToolsInputAction({ notes });
                }}
                editable={isEditable}
                onKeyPress={handleKeyPress}
                multiline
                style={{ height: 200 }}
              />
            </Col>
            <Row>
              <Col xs={12} md={3}>
                <Button.EditTool />
              </Col>
              <Col xs={12} md={3}>
                <Button
                  title='Previous'
                  fullWidth
                  onPress={handlePreviousStep}
                />
              </Col>

              <Col xs={12} md={3}>
                <Button fullWidth title='Next' onPress={handleNextStep} />
              </Col>
              <Col xs={12} md={3}>
                <Button
                  fullWidth
                  title='Save'
                  disabled={
                    input.partNumber === '' ||
                    input.nomenclature === '' ||
                    input.serialNumber === '' ||
                    !isEditable
                  }
                  onPress={handleSubmit}
                />
              </Col>
            </Row>
          </>
        );
      case 4:
        return (
          <>
            <Row>
              <Col xs={12} md={6}>
                <TouchableOpacity
                  onPress={() =>
                    setNextCalibrationCalendarVisible(
                      !nextCalibrationCalendarVisible
                    )
                  }
                >
                  <Input
                    placeholder='Type in Next Calibration'
                    label='Next Calibration'
                    value={
                      input.nextCalibrationTestDueDate ==
                      '1900-01-01T00:00:00.000Z'
                        ? ''
                        : input.nextCalibrationTestDueDate
                    }
                    onChangeText={(nextCalibrationTestDueDate) =>
                      setToolsInputAction({ nextCalibrationTestDueDate })
                    }
                    onClick={() => {
                      setNextCalibrationCalendarVisible(
                        !nextCalibrationCalendarVisible
                      );
                    }}
                    rightIcon={
                      <Ionicons name='calendar' size={20} color='#00205b' />
                    }
                    editable={isEditable}
                    onPress={() => {
                      setNextCalibrationCalendarVisible(true);
                    }}
                  />
                </TouchableOpacity>
              </Col>
              <Col xs={12} md={6}>
                <TouchableOpacity
                  onPress={() => setNextPMICalendarVisible(true)}
                >
                  <Input
                    placeholder='Type in Next PMI'
                    label='Next PMI'
                    value={
                      input.nextPMIDate == '1900-01-01T00:00:00.000Z'
                        ? ''
                        : input.nextPMIDate
                    }
                    onChangeText={(nextPMIDate) =>
                      setToolsInputAction({ nextPMIDate })
                    }
                    onClick={() => {
                      setNextPMICalendarVisible(!nextPMICalendarVisible);
                    }}
                    rightIcon={
                      <Ionicons name='calendar' size={20} color='#00205b' />
                    }
                    editable={isEditable}
                  />
                </TouchableOpacity>
              </Col>
            </Row>
            <Row>
              <Col xs={12} md={6}>
                <Input
                  selectedItem={(item) => item.label}
                  rowTextForSelection={(item) => item.label}
                  dropdown
                  dropdownChoices={[
                    { label: 'Yes', value: true },
                    { label: 'No', value: false },
                  ]}
                  placeholder='Type in Calibration Required'
                  label='Calibration Required'
                  value={input.calibrationRequired == false ? 'No' : 'Yes'}
                  onSelect={(item) =>
                    setToolsInputAction({ calibrationRequired: item.value })
                  }
                  editable={isEditable}
                />
              </Col>
              <Col xs={12} md={3}>
                <Input
                  selectedItem={(item) => item.label}
                  rowTextForSelection={(item) => item.label}
                  dropdown
                  dropdownChoices={[
                    { label: 'Yes', value: true },
                    { label: 'No', value: false },
                  ]}
                  placeholder='Type in Load Test Required'
                  label='Load Test Required'
                  value={input.loadTestRequired == false ? 'No' : 'Yes'}
                  onSelect={(item) => {
                    if (item.value == true) {
                      setToolsInputAction({
                        loadTestDueDate: moment()
                          .add(364, 'days')
                          .format('MM-DD-YYYY'),
                      });
                    } else {
                      setToolsInputAction({
                        loadTestDueDate: '',
                      });
                    }

                    setToolsInputAction({ loadTestRequired: item.value });
                  }}
                  editable={isEditable}
                />
              </Col>
              <Col xs={12} md={3}>
                <Input
                  placeholder='Type in Load Test Date'
                  label='Load Test Date'
                  value={
                    input.loadTestDueDate == '1900-01-01T00:00:00.000Z'
                      ? ''
                      : input.loadTestDueDate
                  }
                  onChangeText={(item) =>
                    setToolsInputAction({ loadTestDueDate: item.value })
                  }
                  editable={isEditable}
                  onClick={() => {
                    setLoadTestDatePickerActiveAction(
                      !loadTestDatePickerActive
                    );
                  }}
                  rightIcon={
                    <Ionicons name='calendar' size={20} color='#00205b' />
                  }
                />
              </Col>
            </Row>
            <Row>
              <Col xs={12} md={6}>
                <Input
                  editable={isEditable}
                  placeholder='Type in Field Tool Status'
                  label='Field Tool Status'
                  value={input.statusDescription}
                  onSelect={(toolStatus) =>
                    setToolsInputAction({
                      toolStatusCode: toolStatus.statusCode.trim(),
                      statusDescription: toolStatus.statusDescription.trim(),
                    })
                  }
                  selectedItem={(item) => item.statusDescription}
                  rowTextForSelection={(item) => item.statusDescription}
                  dropdown
                  dropdownChoices={searchResults.filter((item) => {
                    return item.statusDescription !== 'Unserviceable';
                  })}
                />
              </Col>
              <Col xs={12} md={6}>
                <Input
                  editable={isEditable}
                  placeholder='Type in Checkouts'
                  label='Checkouts'
                  value={input.checkOuts}
                  onChangeText={(checkOuts) =>
                    setToolsInputAction({ checkOuts })
                  }
                  number
                />
              </Col>
            </Row>
            <Row>
              <Col xs={12} md={6}>
                <Input
                  editable={auth.user.tenantId == 1 ? isEditable : false}
                  placeholder='Type in OEM Status'
                  label='OEM Status'
                  value={input.status}
                  onChangeText={(status) =>
                    setToolsInputAction({
                      status: status.trim(),
                    })
                  }
                />
              </Col>
              <Col xs={12} md={6}>
                <Input
                  editable={isEditable}
                  placeholder='Type in Rhinestahl Status'
                  label='Rhinestahl Status'
                  value={input.toolStatus}
                  onChangeText={(toolStatus) =>
                    setToolsInputAction({ toolStatus })
                  }
                />
              </Col>
            </Row>
            <Row>
              <Col xs={12} md={6}>
                <Input
                  placeholder='Type in PMI Comments'
                  label='PMI Comments'
                  value={input.PMIComments}
                  onChangeText={(PMIComments) =>
                    setToolsInputAction({ PMIComments })
                  }
                  editable={isEditable}
                />
              </Col>
              <Col xs={12} md={6}>
                <Input
                  placeholder='Type in Comments'
                  label='Comments'
                  value={input.comments}
                  onChangeText={(comments) => setToolsInputAction({ comments })}
                  editable={isEditable}
                />
              </Col>
            </Row>
            <Row>
              <Col xs={12} md={6}>
                <Input
                  placeholder='Type in GVI Instructions'
                  label='GVI Instructions'
                  value={input.GVIInstructions}
                  onChangeText={(GVIInstructions) =>
                    setToolsInputAction({ GVIInstructions })
                  }
                  editable={isEditable}
                />
              </Col>
              <Col xs={12} md={6}>
                <Input
                  placeholder='Type in Results'
                  label='Results'
                  value={input.results}
                  onChangeText={(results) => setToolsInputAction({ results })}
                  editable={isEditable}
                  onKeyPress={handleKeyPress}
                />
              </Col>
            </Row>
            <Row>
              <Col xs={12} md={3} style={{ justifyContent: 'center' }}>
                <Switch
                  label='Active'
                  value={input.activeFlag == 'Y' ? true : false}
                  onValueChange={(activeFlag) =>
                    setToolsInputAction({ activeFlag: activeFlag ? 'Y' : 'N' })
                  }
                  disabled={!isEditable}
                />
              </Col>
              <Col xs={12} md={6} style={{ justifyContent: 'center' }}>
                <Switch
                  label='Overridden'
                  value={input.overridebytenantStatus ? true : false}
                  disabled
                />
              </Col>
              <Col xs={12} md={3}>
                <Button
                  title='History'
                  fullWidth
                  onPress={() => getToolHistoryThunk(input.id)}
                  color={'#00205b'}
                />
              </Col>
            </Row>
            <Row>
              <Col xs={12} md={3}>
                <Button.EditTool />
              </Col>
              <Col xs={12} md={3}>
                <Button
                  title='Previous'
                  fullWidth
                  onPress={handlePreviousStep}
                />
              </Col>
              <Col xs={12} md={3}>
                <Button fullWidth title='Next' onPress={handleNextStep} />
              </Col>
              <Col xs={12} md={3}>
                <Button
                  fullWidth
                  title='Save'
                  disabled={
                    input.partNumber === '' ||
                    input.nomenclature === '' ||
                    input.serialNumber === '' ||
                    !isEditable
                  }
                  onPress={handleSubmit}
                />
              </Col>
            </Row>
          </>
        );
      case 5:
        return (
          <>
            <Row>
              <Col xs={12} md={2}>
                <Button
                  title='Add Inspection'
                  disabled={!isEditable}
                  fullWidth
                  onPress={() => {
                    setInspectionsInputAction({ id: null });
                    setInspectionsEditAction(false);
                    setInspectionsShowModalAction(true);
                  }}
                  color={'#00205b'}
                />
              </Col>
              <Col xs={12} md={2}>
                <Button
                  title='Inspection History'
                  fullWidth
                  onPress={() => {
                    getToolInspectionHistoryThunk(input.id);
                    setShowInspectionHistoryModal(true);
                  }}
                  color={'#00205b'}
                />
              </Col>
              <Col xs={12} md={2}>
                <Button
                  title='Perform Inspection'
                  fullWidth
                  disabled={inspections.list.length === 0}
                  onPress={async () => {
                    await getInspectionQuestionsThunk(
                      inspections.list.map((x) => x.inspectionTypeId).join(',')
                    );
                    setShowInspectionQuestionsModalAction(true);
                  }}
                  color={'#00205b'}
                />
              </Col>
            </Row>
            <Row>
              <Col xs={12}>
                <List.Inspections
                  disabled={!isEditable}
                  onRemove={() => {
                    removeInspectionsThunk();
                  }}
                />
              </Col>
            </Row>
            <Row>
              <Col xs={12} md={3}>
                <Button.EditTool />
              </Col>
              <Col xs={12} md={3}>
                <Button
                  title='Previous'
                  fullWidth
                  onPress={handlePreviousStep}
                />
              </Col>
              <Col xs={12} md={3}>
                <Button fullWidth title='Next' onPress={handleNextStep} />
              </Col>
              <Col xs={12} md={3}>
                <Button
                  fullWidth
                  title='Save'
                  disabled={
                    input.partNumber === '' ||
                    input.nomenclature === '' ||
                    input.serialNumber === '' ||
                    !isEditable
                  }
                  onPress={handleSubmit}
                />
              </Col>
            </Row>
          </>
        );
      case 6:
        return (
          <>
            <Row>
              <Col xs={12} md={4}>
                <Input
                  placeholder='Type in Reservation Date'
                  label='Reservation Date'
                  value={
                    reservationInput.reservationstartDate &&
                    reservationInput.reservationendDate
                      ? moment(reservationInput.reservationstartDate).format(
                          'MM/DD/YYYY'
                        ) +
                        ' - ' +
                        moment(reservationInput.reservationendDate).format(
                          'MM/DD/YYYY'
                        )
                      : ''
                  }
                  onChangeText={(nextPMIDate) =>
                    setReservationInputAction({ reservationDate: nextPMIDate })
                  }
                  onClick={() => {
                    if (isEditable) {
                      setReservationCalendarVisible(true);
                    }
                  }}
                  rightIcon={
                    <Ionicons name='calendar' size={20} color='#00205b' />
                  }
                  editable={isEditable}
                />
              </Col>
              <Col xs={12} md={4}>
                <Input
                  placeholder='Type in Reservation Work Order'
                  label='Reservation Work Order'
                  value={reservationInput.reservationworkOrder}
                  onChangeText={(reservationworkOrder) =>
                    setReservationInputAction({ reservationworkOrder })
                  }
                  editable={isEditable}
                />
              </Col>

              <Col xs={12} md={4}>
                <Input
                  placeholder='Type in Reservation Engine Number'
                  label='Reservation Engine Number'
                  value={reservationInput.reservationengineNumber}
                  onChangeText={(reservationengineNumber) =>
                    setReservationInputAction({ reservationengineNumber })
                  }
                  editable={isEditable}
                />
              </Col>
              <Col>
                <Input
                  placeholder='Type in Reservation Notes'
                  label='Reservation Notes'
                  value={reservationInput.reservationNotes}
                  onChangeText={(reservationNotes) =>
                    setReservationInputAction({ reservationNotes })
                  }
                  editable={isEditable}
                />
              </Col>
            </Row>
            {reservedTools.length > 0 && (
              <>
                <Gap />

                <List.ReservationInformation />
                <Gap />
              </>
            )}
            <Row>
              <Col xs={12} md={3}>
                <Button.EditTool />
              </Col>
              <Col xs={12} md={2}>
                <Button
                  color={'#00205b'}
                  title='Add Reservation'
                  fullWidth
                  onPress={() => {
                    reserveSingleToolThunk({
                      input: {
                        ...reservationInput,
                        id: null, //reservationInput.id,
                        toolId: input.id,
                      },
                    });
                  }}
                  disabled={
                    !reservationInput.reservationstartDate ||
                    !reservationInput.reservationendDate ||
                    !reservationInput.reservationworkOrder ||
                    !reservationInput.reservationengineNumber
                  }
                />
              </Col>
              <Col xs={12} md={2}>
                <Button
                  title='Previous'
                  fullWidth
                  onPress={handlePreviousStep}
                />
              </Col>

              <Col xs={12} md={2}>
                <Button fullWidth title='Next' onPress={handleNextStep} />
              </Col>
              <Col xs={12} md={3}>
                <Button
                  fullWidth
                  title='Save'
                  disabled={
                    input.partNumber === '' ||
                    input.nomenclature === '' ||
                    input.serialNumber === '' ||
                    !isEditable
                  }
                  onPress={handleSubmit}
                />
              </Col>
            </Row>
          </>
        );
      case 7:
        return (
          <>
            <Button
              title='Add Document'
              disabled={!isEditable}
              onPress={async () => {
                const document = await DocumentPicker.getDocumentAsync({});
                if (
                  document.size > (process.env.MAX_UPLOAD_FILE_SIZE || 3000000)
                ) {
                  setAlertThunk({
                    type: 'error',
                    message: 'Files larger than 5.1MB are not allowed.',
                  });
                } else {
                  const extra = {
                    id: null,
                    notes: document.name,
                    tenantId: tenant.input.id,
                    mimeType: document.mimeType,
                    type: 'extra',
                    body: document.uri,
                    toolid: input.id,
                  };
                  setExtraDocumentAction(extra);
                  upsertExtraDocumentThunk({
                    navigation: props.navigation,
                    input: extra,
                    message: 'Document Upserted',
                  });
                }
              }}
            />
            <Gap />
            <CustomModal.DocImage />
            <List.Documents disabled={!isEditable} />
            <Gap />
            <Row>
              <Col xs={12} md={4}>
                <Button.EditTool />
              </Col>
              <Col xs={12} md={4}>
                <Button
                  title='Previous'
                  fullWidth
                  onPress={handlePreviousStep}
                />
              </Col>
              <Col xs={12} md={4}>
                <Button
                  fullWidth
                  title='Save'
                  disabled={
                    input.partNumber === '' ||
                    input.nomenclature === '' ||
                    input.serialNumber === '' ||
                    !isEditable
                  }
                  onPress={handleSubmit}
                />
              </Col>
            </Row>
          </>
        );
    }
  };

  const renderStepIndicators = () => {
    const steps = [
      'Summary',
      'Location',
      'Detail',
      'Tool Care',
      'Inspection',
      'Reservation',
      'Documents',
    ];

    return steps.map((step, index) => (
      <TouchableOpacity
        key={index}
        onPress={() => setEditStepCountAction(index + 1)}
        style={[
          styles.stepIndicator,
          editStepCount === index + 1 && styles.currentStepIndicator,
        ]}
      >
        <Text
          key={index}
          style={[
            styles.stepIndicator,
            editStepCount === index + 1 && styles.currentStepIndicator,
          ]}
        >
          {step}
        </Text>
      </TouchableOpacity>
    ));
  };

  const handleSubmit = () => {
    upsertToolsThunk({
      navigation: props.navigation,
      input,
      message: 'Tool created successfully!',
    });
  };

  return (
    <Card style={styles.container}>
      <ScrollView
        horizontal
        contentContainerStyle={styles.stepIndicatorsContainer}
      >
        {renderStepIndicators()}
      </ScrollView>
      <Divider />
      <Gap />
      {renderFormStep()}
    </Card>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    padding: 16,
  },
  stepIndicatorsContainer: {
    flexDirection: 'row',
    alignSelf: 'center',
    marginBottom: 16,
    width: '100%',
  },
  stepIndicator: {
    fontFamily: 'Barlow_600SemiBold',
    flex: 1,
    fontSize: 18,
    textAlign: 'center',
    paddingHorizontal: 8,
    paddingVertical: 4,
    borderRadius: 8,
    marginRight: 8,
  },
  currentStepIndicator: {
    backgroundColor: '#e8e9ea',
  },
  stepText: {
    fontSize: 20,
    marginBottom: 16,
  },
});

export default EditToolStepForm;
