import { useStoreActions, useStoreState } from 'easy-peasy';
import Button from '../../presentational/Button';
import CustomModal from '../../presentational/Modal';
import { Ionicons } from '@expo/vector-icons';
import { Dimensions, Platform, TouchableOpacity, View } from 'react-native';
import { FlatList } from 'react-native';
import Text from '../../presentational/Text';
import { Divider } from '@rneui/themed';
import Gap from '../../presentational/Gap';
import Row from '../../presentational/Row';
import Col from '../../presentational/Col';
import { useState } from 'react';
import Input from '../../presentational/Input';

const { width } = Dimensions.get('window');

export default function ToolCheckoutModal() {
  const {
    auth: { user },
    tools: {
      searchResults,
      myToolFilter,
      toolCheckOutModal,
      checkoutToolList,
      toolPreCheck,
      validCheckOutToolList,
      checkoutComment,
    },
    workPackage,
    workPackage: { checkoutPackageList },
  } = useStoreState((state) => state);

  const {
    getWorkPackageThunk,
    setToolCheckOutModalAction,
    checkoutWorkPackageThunk,
    setValidCheckOutToolListAction,
    setReservationModalAction,
    setSelectedPrecheckAction,
    setPreCheckToolModalAction,
    setWorkOrderSerialModalAction,
    setWorkPackageWOSerialModalAction,
    setCheckoutCommentAction,
    setCheckoutMapAction,
  } = useStoreActions((actions) => actions);

  const [invalid, setInvalid] = useState(false);

  const [understand, setUnderstand] = useState(false);
  return (
    <CustomModal
      style={{ width: 800 }}
      width='50%'
      height='50%'
      isVisible={toolCheckOutModal}
      title={'Check-Out Tool'}
      onClose={() => {
        setCheckoutMapAction({});
        setToolCheckOutModalAction(false);
      }}
    >
      <FlatList
        ListHeaderComponent={
          <Row>
            <Col xs={5} sm={5}>
              <Text style={{ fontSize: 17 }}>Part Number</Text>
            </Col>
            <Col xs={3} sm={3}>
              <Text style={{ fontSize: 17 }}>Location</Text>
            </Col>
            <Col xs={3} sm={3}>
              <Text style={{ fontSize: 17 }}>Bar Code</Text>
            </Col>
            <Col xs={1} sm={1}></Col>
          </Row>
        }
        data={
          !myToolFilter
            ? searchResults
                .filter((obj) =>
                  checkoutToolList && checkoutToolList.length
                    ? checkoutToolList
                        .split(',')
                        .some((itemId) => itemId === obj.id)
                    : []
                )
                .reduce((array, current) => {
                  if (array.some((item) => item.id === current.id)) {
                    const toolList = array.filter(
                      (item) => item.id === current.id
                    );
                    array.push({ ...current, index: toolList.length });
                  } else {
                    array.push({ ...current, index: 0 });
                  }
                  return array;
                }, [])
            : workPackage.searchResults.filter((obj) =>
                checkoutPackageList && checkoutPackageList.length > 0
                  ? checkoutPackageList
                      .split(',')
                      .some((itemId) => itemId === obj.id.toString())
                  : []
              )
        }
        renderItem={({ item }, index) => {
          if (
            item.NotAllToolsAvailableFlag == true ||
            item.inspectionRequired == 'Y'
          ) {
            setInvalid(true);
          }
          return (
            <TouchableOpacity>
              <Row style={{ alignItems: 'center' }}>
                <Col xs={5} sm={5}>
                  <Text style={{ fontSize: 17, color: '#ab2328' }}>
                    {!myToolFilter ? item.partNumber : item.name} (
                    {item.serialNumber})
                  </Text>
                </Col>
                <Col xs={3} sm={3}>
                  <Text style={{ fontSize: 17 }}>{item.storageLocation}</Text>
                </Col>
                <Col xs={3} sm={3}>
                  <Text style={{ fontSize: 17 }}>{item.barcodescanid}</Text>
                </Col>
                <Col xs={1} sm={1}>
                  {item.inspectionRequired !== 'Y' &&
                  toolPreCheck &&
                  toolPreCheck.length > 0 &&
                  toolPreCheck.some(
                    (obj) =>
                      (obj.inspectionJSON &&
                        obj.inspectionJSON.some((i) => i.id == item.id)) ||
                      (obj.reservationJSON &&
                        obj.reservationJSON.some((i) => i.id == item.id))
                  ) ? (
                    <TouchableOpacity
                      onPress={() => {
                        setSelectedPrecheckAction(
                          (toolPreCheck &&
                            toolPreCheck[0] &&
                            toolPreCheck[0].inspectionJSON &&
                            toolPreCheck[0].inspectionJSON.filter(
                              (i) => i.id == item.id
                            )[item.index]) ||
                            (toolPreCheck[0].reservationJSON &&
                              toolPreCheck[0].reservationJSON.filter(
                                (i) => i.id == item.id
                              )[0])
                        );
                        setPreCheckToolModalAction(true);
                      }}
                    >
                      <Ionicons
                        name='warning'
                        size={24}
                        color='#F6BE00'
                        style={{ padding: 5 }}
                      />
                    </TouchableOpacity>
                  ) : item.inspectionRequired == 'Y' ? (
                    <TouchableOpacity
                      onPress={() => {
                        setSelectedPrecheckAction(
                          (toolPreCheck &&
                            toolPreCheck[0] &&
                            toolPreCheck[0].inspectionJSON &&
                            toolPreCheck[0].inspectionJSON != null &&
                            toolPreCheck[0].inspectionJSON.filter(
                              (i) => i.id == item.id
                            )[item.index]) ||
                            (toolPreCheck &&
                              toolPreCheck[0] &&
                              toolPreCheck[0].reservationJSON.filter(
                                (i) => i.id == item.id
                              )[0])
                        );
                        setPreCheckToolModalAction(true);
                      }}
                    >
                      <Ionicons
                        name='alert'
                        size={24}
                        color='#ab2328'
                        style={{ padding: 5 }}
                      />
                    </TouchableOpacity>
                  ) : (
                    <Ionicons
                      name='checkmark'
                      size={24}
                      color='green'
                      style={{ padding: 5 }}
                    />
                  )}
                </Col>
              </Row>

              <Divider />
            </TouchableOpacity>
          );
        }}
      />
      <Gap />
      <Row>
        <Col xs={12} sm={12}>
          <Input
            label='Comments'
            value={checkoutComment}
            onChangeText={(comment) => setCheckoutCommentAction(comment)}
            multiline
            style={{ height: Platform.OS == 'web' ? 150 : 25 }}
          />
        </Col>
      </Row>

      <Text>
        I acknowledge that I am checking out these tools in good working order.
        I understand that it is my responsibility to use them safely and
        correctly. I am aware of the potential hazards associated with their
        operation, and I will take appropriate precautions to prevent any
        accidents or injuries. If there are any issues or malfunctions, I will
        immediately notify the relevant personnel. I agree to return the tools
        in the same condition as I received them and accept full responsibility
        for any damages or loss that may occur during my use. I understand that
        any consequences resulting from my negligence or misuse of the tools are
        my sole liability.
      </Text>
      <Gap />

      <Row>
        <Col xs={12} sm={6}>
          <Button
            disabled={invalid}
            color='#00205b'
            fullWidth
            onPress={() => {
              if (!myToolFilter) {
                setReservationModalAction(true);
                setToolCheckOutModalAction(false);
              } else {
                checkoutWorkPackageThunk({
                  navigation,
                });
                getWorkPackageThunk({
                  id: null,
                  tenantId: user.tenantId,
                });
                setToolCheckOutModalAction(false);
              }
            }}
          >
            {!myToolFilter ? 'Reserve Tool (s)' : 'Reserve Work Package'}
          </Button>
        </Col>
        <Col xs={12} sm={6}>
          <Button
            disabled={invalid}
            fullWidth
            onPress={() => {
              if (!myToolFilter) {
                setWorkOrderSerialModalAction(true);
              } else {
                setWorkPackageWOSerialModalAction(true);
              }
            }}
          >
            {!myToolFilter ? 'Check-Out Tool (s)' : 'Check-Out Work Package'}
          </Button>
        </Col>
      </Row>
    </CustomModal>
  );
}
