import moment from 'moment';
import { action } from 'easy-peasy';
import { defaultInputState } from './state';

const actions = {
  setToolsSearchResultsAction: action((state, searchResults) => {
    let uniqueMap = searchResults.reduce((map, item) => {
      if (!map[`${item.partNumber}-${item.serialNumber}`]) {
        map[`${item.partNumber}-${item.serialNumber}`] = item;
      }
      return map;
    }, {});

    const uniqueMapList = Object.values(uniqueMap);

    let statusMap = uniqueMapList.reduce((map, item) => {
      if (item.tenantLocation) {
        if (!map[item.tenantLocation]) {
          map[item.tenantLocation] = {
            name: state.location.locationMap[item.tenantLocation]?.name?.trim(),
            locationStatusMap: {},
            count: 0,
            expanded: false,
          };
        }
      }

      if (item.statusDescription && item?.tenantLocation) {
        if (
          !map[item.tenantLocation]?.locationStatusMap[item.statusDescription]
        ) {
          let itemType = 'primary';
          if (item.toolStatusCode === 'AV') itemType = 'success';
          if (item.toolStatusCode === 'CO' || item.toolStatusCode === 'BRKN')
            itemType = 'error';
          if (item.toolStatusCode === 'INSP' || item.toolStatusCode === 'RSVD')
            itemType = 'warning';
          map[item.tenantLocation].locationStatusMap[item.statusDescription] = {
            tools: [item],
            type: itemType,
          };
          map[item.tenantLocation].count++;
        } else {
          map[item.tenantLocation].count++;
          map[item.tenantLocation].locationStatusMap[
            item.statusDescription
          ].tools.push(item);
        }
      }
      return map;
    }, {});

    let oemStatusMap = uniqueMapList.reduce((map, item) => {
      if (item.tenantLocation) {
        if (!map[item.tenantLocation]) {
          map[item.tenantLocation] = {
            name: state.location.locationMap[item.tenantLocation]?.name?.trim(),
            locationStatusMap: {},
            count: 0,
            expanded: false,
          };
        }
      }

      var status = item?.status?.trim();

      if (status && item?.tenantLocation) {
        if (!map[item?.tenantLocation]?.locationStatusMap[status]) {
          let itemType = 'primary';
          if (status === 'RC') itemType = 'success';
          if (status === 'TC') itemType = 'error';
          if (status === 'OB') itemType = 'warning';
          map[item?.tenantLocation].locationStatusMap[status] = {
            tools: [item],
            type: itemType,
          };
          map[item.tenantLocation].count++;
        } else {
          map[item.tenantLocation].count++;
          map[item.tenantLocation].locationStatusMap[status].tools.push(item);
        }
      }
      return map;
    }, {});

    state.tools.toolsByStatusMap = statusMap;
    state.tools.toolsByOEMStatusMap = oemStatusMap;
    state.tools.uniquePartList = Object.values(uniqueMap);
    state.tools.overriddenTools = Object.values(uniqueMap).filter(
      (item) => !!item.overridebytenantStatus
    );
    state.tools.searchResults = searchResults;
  }),
  setToolsSearchInputAction: action((state, searchInput) => {
    state.tools.searchInput = searchInput;
  }),
  setIsEditableToolsAction: action((state, isEditable) => {
    state.tools.isEditable = isEditable;
  }),
  setIsEditableBrokenToolsAction: action((state, isEditable) => {
    state.tools.brokenToolEditable = isEditable;
  }),
  setToolsInputAction: action((state, input) => {
    state.tools.input = {
      ...state.tools.input,
      ...input,
    };
  }),
  resetToolsInputAction: action((state, input) => {
    state.tools.input = defaultInputState;
  }),
  setBulkToolStringAction: action((state, bulkToolString) => {
    state.tools.bulkToolString = bulkToolString;
  }),
  setNextCalibrationCalendarVisible: action((state, toolCalendarVisible) => {
    state.tools.nextCalibrationDateCalendarVisible = toolCalendarVisible;
  }),
  setNextPMICalendarVisible: action((state, toolCalendarVisible) => {
    state.tools.nextPMIDateCalendarVisible = toolCalendarVisible;
  }),
  setHistoryModalActive: action((state, historyModalActive) => {
    state.tools.historyModalActive = historyModalActive;
  }),

  setMyToolFilterAction: action((state, myToolFilter) => {
    state.tools.myToolFilter = myToolFilter;

    if (myToolFilter) {
      state.tools.checkoutToolList = [];
    } else {
      state.workPackage.checkoutPackageList = [];
    }
  }),
  setToolCheckOutModalAction: action((state, toolCheckOutModal) => {
    state.tools.toolCheckOutModal = toolCheckOutModal;
  }),
  setToolCheckInModalAction: action((state, toolCheckInModal) => {
    state.tools.toolCheckInModal = toolCheckInModal;
  }),
  addToCheckoutToolListAction: action((state, tool) => {
    state.tools.checkoutMap[tool.id] = tool;
    state.tools.checkinToolList = [];
    const checkoutToolList = state.tools.checkoutToolList;

    const toolIndex = checkoutToolList.indexOf(tool.id);

    if (toolIndex === -1) {
      if (checkoutToolList.length > 0) {
        state.tools.checkoutToolList = checkoutToolList + ',' + tool.id;
      } else {
        state.tools.checkoutToolList = tool.id;
      }
    } else {
      if (checkoutToolList.length > 0) {
        const updatedList = checkoutToolList
          .split(',')
          .filter((item) => item !== tool.id)
          .join(',');
        state.tools.checkoutToolList = updatedList;
      } else {
        state.tools.checkoutToolList = '';
      }
    }
  }),

  addToCheckinToolListAction: action((state, tool) => {
    state.tools.checkoutMap[tool.id] = tool;
    state.tools.checkoutToolList = [];

    const checkinToolList = state.tools.checkinToolList;

    const toolIndex = checkinToolList.indexOf(tool.id);

    if (toolIndex === -1) {
      if (checkinToolList.length > 0) {
        state.tools.checkinToolList = checkinToolList + ',' + tool.id;
      } else {
        state.tools.checkinToolList = tool.id;
      }
    } else {
      if (checkinToolList.length > 0) {
        const updatedList = checkinToolList
          .split(',')
          .filter((item) => item !== tool.id)
          .join(',');
        state.tools.checkinToolList = updatedList;
      } else {
        state.tools.checkinToolList = '';
      }
    }
  }),
  setCheckoutToolListAction: action((state, checkoutToolList) => {
    state.tools.checkoutToolList = checkoutToolList;
  }),
  setCheckinToolListAction: action((state, checkinToolList) => {
    state.tools.checkinToolList = checkinToolList;
  }),

  setSelectedToolInspectionTypesAction: action(
    (state, selectedToolInspectionTypes) => {
      state.tools.selectedToolInspectionTypes = selectedToolInspectionTypes;

      state.tools.input.inspectionTypes = selectedToolInspectionTypes
        .map((item) => item.id)
        .join(',');
    }
  ),

  addToReserveToolListAction: action((state, toolId) => {
    if (state.tools.reserveToolList == toolId) {
      state.tools.reserveToolList = '';
    } else {
      state.tools.reserveToolList = toolId;
    }
    // state.tools.reserveToolList = toolId;
  }),
  setShowInspectionQuestionsModalAction: action(
    (state, showInspectionQuestionsModal) => {
      state.tools.showInspectionQuestionsModal = showInspectionQuestionsModal;
    }
  ),
  setValidCheckInToolListAction: action((state, tool) => {
    const checkinToolList = state.tools.validCheckInToolList;

    const toolIndex = checkinToolList.indexOf(tool.id);

    if (toolIndex === -1) {
      if (checkinToolList.length > 0) {
        state.tools.validCheckInToolList = checkinToolList + ',' + tool.id;
      } else {
        state.tools.validCheckInToolList = tool.id;
      }
    } else {
      if (checkinToolList.length > 0) {
        const updatedList = checkinToolList
          .split(',')
          .filter((item) => item !== tool.id)
          .join(',');
        state.tools.validCheckInToolList = updatedList;
      } else {
        state.tools.validCheckInToolList = '';
      }
    }

    state.tools.checkoutMap[tool.id] = tool;
  }),

  setValidCheckOutToolListAction: action((state, tool) => {
    const checkoutToolList = state.tools.validCheckOutToolList;

    const toolIndex = checkoutToolList.indexOf(tool.id);

    if (toolIndex === -1) {
      if (checkoutToolList.length > 0) {
        state.tools.validCheckOutToolList = checkoutToolList + ',' + tool.id;
      } else {
        state.tools.validCheckOutToolList = tool.id;
      }
    } else {
      if (checkoutToolList.length > 0) {
        const updatedList = checkoutToolList
          .split(',')
          .filter((item) => item !== tool.id)
          .join(',');
        state.tools.validCheckOutToolList = updatedList;
      } else {
        state.tools.validCheckOutToolList = '';
      }
    }

    state.tools.checkoutMap[tool.id] = tool;
  }),

  setReservationInputAction: action((state, input) => {
    state.tools.reservationInput = {
      ...state.tools.reservationInput,
      ...input,
    };
  }),
  setReservationModalAction: action((state, reservationModal) => {
    state.tools.reservationModal = reservationModal;
  }),
  setShowInspectionHistoryModal: action((state, showInspectionHistoryModal) => {
    state.tools.showInspectionHistoryModal = showInspectionHistoryModal;
  }),
  setToolInspectionHistoryAction: action((state, inspectionHistory) => {
    state.tools.inspectionHistory = inspectionHistory;
  }),

  setToolInspectionResultInputAction: action((state, input) => {
    state.tools.inspectionInput = {
      ...state.tools.inspectionInput,
      ...input,
    };
  }),

  setInspectionTypeId: action((state, inspectionTypeId) => {
    state.tools.inspectionTypeId = inspectionTypeId;
  }),

  setCurrentStep: action((state, currentStep) => {
    state.tools.currentStep = currentStep;
  }),
  setCurrentSubStep: action((state, currentStep) => {
    state.tools.currentSubStep = currentStep;
  }),
  setReservationCalendarVisible: action((state, reservationCalendarVisible) => {
    state.tools.reservationCalendarVisible = reservationCalendarVisible;
  }),

  setReservedToolsAction: action((state, reservedTools) => {
    state.tools.reservedTools = reservedTools;
  }),
  setToolInspectionQuestionsAction: action((state, inspectionQuestions) => {
    state.tools.inspectionQuestions = inspectionQuestions;
  }),
  setToolPrecheckAction: action((state, toolPreCheck) => {
    state.tools.toolPreCheck = toolPreCheck;
  }),
  setSelectedPrecheckAction: action((state, selectedToolPreCheck) => {
    state.tools.selectedToolPreCheck = selectedToolPreCheck;
  }),
  setPreCheckToolModalAction: action((state, preCheckToolModal) => {
    state.tools.preCheckToolModal = preCheckToolModal;
  }),
  clearValidListAction: action((state) => {
    state.tools.validCheckInToolList = '';
    state.tools.validCheckOutToolList = '';
  }),

  setCheckoutInputAction: action((state, input) => {
    state.tools.checkoutInput = {
      ...state.tools.checkoutInput,
      ...input,
    };
  }),
  setWorkOrderSerialModalAction: action((state, workOrderSerialModal) => {
    state.tools.workOrderSerialModal = workOrderSerialModal;
  }),
  setBrokenToolModalVisibleAction: action((state, brokenToolModalVisible) => {
    state.tools.brokenToolModalVisible = brokenToolModalVisible;
  }),
  setBrokenToolInputAction: action((state, input) => {
    state.tools.brokenToolInput = {
      ...state.tools.brokenToolInput,
      ...input,
    };
  }),
  resetBrokenToolInputAction: action((state) => {
    state.tools.brokenToolInput = {
      toolId: null,
      repositoryid: null,
      toolStatusCode: 'BRKN',
      toolStatusDescription: 'Unserviceable Tool',
      toolbrokendescription: '',
    };
  }),

  setBrokenToolCalendarVisible: action((state, brokenToolCalendarVisible) => {
    state.tools.brokenToolCalendarVisible = brokenToolCalendarVisible;
  }),
  setBrokenToolHistoryAction: action((state, brokenToolHistory) => {
    state.tools.brokenToolHistory = brokenToolHistory;
  }),

  setToolCategoryListAction: action((state, toolCategoryList) => {
    state.tools.toolCategoryList = toolCategoryList;
  }),

  setToolCategoryIsEditableAction: action((state, isEditable) => {
    state.tools.toolCategoryIsEditable = isEditable;
  }),

  setToolCategoryInputAction: action((state, input) => {
    state.tools.toolCategoryInput = {
      ...state.tools.toolCategoryInput,
      ...input,
    };
  }),

  resetToolCategoryInputAction: action((state) => {
    state.tools.toolCategoryInput = {
      id: null,
      categoryCode: '',
      categoryDescription: '',
    };
  }),

  setToolCategorySearchInputAction: action((state, searchInput) => {
    state.tools.toolCategorySearchInput = searchInput;
  }),

  setBrokenToolDropdownSearchInputAction: action((state, searchInput) => {
    state.tools.brokenToolSearchInput = searchInput;
  }),
  setOEMStatusListAction: action((state, list) => {
    state.tools.oemStatusList = list;
  }),

  setOEMSearchInputAction: action((state, searchInput) => {
    state.tools.oemSearchInput = searchInput;
  }),

  setOEMInputAction: action((state, input) => {
    state.tools.oemInput = {
      ...state.tools.oemInput,
      ...input,
    };
  }),

  setOEMIsEditableAction: action((state, isEditable) => {
    state.tools.oemIsEditable = isEditable;
  }),
  resetOEMStatusInputAction: action((state, input) => {
    state.tools.oemInput = {
      id: null,
      tenantId: null,
      OEMstatusCode: '',
      OEMstatusDescription: '',
    };
  }),

  setScannerOpenAction: action((state, scannerOpen) => {
    state.tools.scannerOpen = scannerOpen;
  }),
  setCameraPermissionAction: action((state, hasCameraPermission) => {
    state.tools.hasCameraPermission = hasCameraPermission;
  }),

  setToolInformationModalVisibleAction: action(
    (state, toolInformationModalVisible) => {
      state.tools.toolInformationModalVisible = toolInformationModalVisible;
    }
  ),
  setLoadTestDatePickerActiveAction: action(
    (state, loadTestDatePickerActive) => {
      state.tools.loadTestDatePickerActive = loadTestDatePickerActive;
    }
  ),

  setIsNewToolPhotoAction: action((state, isNewToolPhoto) => {
    state.tools.isNewToolPhoto = isNewToolPhoto;
  }),

  setEditStepCountAction: action((state, stepCount) => {
    state.tools.editStepCount = stepCount;
  }),
  setToolHistoryAction: action((state, history) => {
    const list = history
      .filter(
        (item) =>
          item.workOrder &&
          (item.statusCode.trim() === 'CI' || item.statusCode.trim() === 'CO')
      )
      .sort((a, b) => {
        return new Date(b.datemodified) - new Date(a.datemodified);
      });
    const map = list.reduce((map, current) => {
      if (!map[current.workOrder]) {
        map[current.workOrder] = { entries: [{ ...current }] };
      } else {
        map[current.workOrder].entries.push(current);
      }
      return map;
    }, {});
    state.tools.toolHistoryMap = map;
    state.tools.toolHistory = [...new Set(list.map((item) => item.workOrder))];
  }),
  setToolHistoryModalAction: action((state, isOpen) => {
    state.tools.showToolHistoryModal = isOpen;
  }),
  setToolTransfersAction: action((state, transfers) => {
    state.tools.toolTransfers = [...transfers];
  }),
  setToolTransferInputAction: action((state, input) => {
    state.tools.toolTransferInput = {
      ...state.tools.toolTransferInput,
      ...input,
    };
  }),
  setTransferModalAction: action((state, isOpen) => {
    state.tools.showToolTransferModal = isOpen;
  }),
  setTransferSearchInputAction: action((state, searchInput) => {
    state.tools.transferSearchInput = searchInput;
  }),
  setApproveTransferModalAction: action((state, isOpen) => {
    state.tools.showApproveTransferModal = isOpen;
  }),
  setReceiveTransferModalAction: action((state, isOpen) => {
    state.tools.showReceiveTransferModal = isOpen;
  }),
  setSelectedTransferAction: action((state, transfer) => {
    state.tools.selectedTransfer = { ...transfer };
  }),
  setIsTransferSelectedAction: action((state, isSelected) => {
    state.tools.transferIsSelected = isSelected;
  }),
  setReportsAction: action((state, reports) => {
    state.tools.reports = reports;
  }),
  setReportsModalAction: action((state, isOpen) => {
    state.tools.showReportsModal = isOpen;
  }),
  setReportTypeAction: action((state, reportType) => {
    state.tools.reportType = reportType;
  }),
  setToolsByEngineAction: action((state, tools) => {
    const toolMap = tools.reduce((map, item) => {
      const itemDate = moment.utc(item.usedDate).format('MM/DD/YYYY');
      const key = `${item.workOrder}-${itemDate}`;
      if (!map[key]) {
        map[key] = {
          key,
          expanded: false,
          engine: item.engineserialNumber,
          workOrder: item.workOrder,
          usedDate: itemDate,
          user: item.tooluser,
          tools: [
            {
              partNumber: item.partNumber,
              serialNumber: item.serialNumber,
              description: item.partDescription,
            },
          ],
        };
      } else {
        map[key].tools.push({
          partNumber: item.partNumber,
          serialNumber: item.serialNumber,
          description: item.partDescription,
        });
      }

      return map;
    }, {});
    state.tools.toolsByEngineMap = toolMap;
    state.tools.toolsByEngine = Object.values(toolMap);
  }),
  expandToolsByEngineAction: action((state, { key, isOpen }) => {
    let item = state.tools.toolsByEngineMap[key];
    item.expanded = isOpen;
    state.tools.toolsByEngineMap[key] = item;
  }),
  expandToolsByStatusAction: action((state, { key, isOpen }) => {
    let item = state.tools.toolsByStatusMap[key];
    item.expanded = isOpen;
    state.tools.toolsByStatusMap[key] = item;
  }),
  expandToolsByOEMStatusAction: action((state, { key, isOpen }) => {
    let item = state.tools.toolsByOEMStatusMap[key];
    item.expanded = isOpen;
    state.tools.toolsByOEMStatusMap[key] = item;
  }),
  resetToolsByEngineAction: action((state) => {
    state.tools.toolsByEngineMap = {};
    state.tools.toolsByEngine = [];
  }),
  setReportListDataAction: action((state, data) => {
    state.tools.reportListData = data;
  }),
  setReportsLoading: action((state, isLoading) => {
    state.tools.reportsLoading = isLoading;
  }),
  setToolUsageListAction: action((state, data) => {
    state.tools.usageMap = data.reduce((map, item) => {
      if (!map[item.toolid]) {
        map[item.toolid] = item;
      }

      return map;
    }, {});
    state.tools.usageList = data;
  }),
  setCheckoutCommentAction: action((state, comment) => {
    state.tools.checkoutComment = comment;
  }),
  setCheckInCommentAction: action((state, comment) => {
    state.tools.checkInComment = comment;
  }),
  setCheckoutMapAction: action((state, data) => {
    state.tools.checkoutMap = data;
  }),
};

export default actions;
