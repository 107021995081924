import { useStoreActions, useStoreState } from 'easy-peasy';
import Button from '../../presentational/Button';
import CustomModal from '../../presentational/Modal';
import Input from '../../presentational/Input';
import Col from '../../presentational/Col';
import Icon from '../../presentational/Icon';
import Gap from '../../presentational/Gap';
import Row from '../../presentational/Row';
import { Platform } from 'react-native';

export default function ReceiveTransferModal({ onSubmit }) {
  const {
    tools: { input, showReceiveTransferModal },
    storageLocation: { searchResults },
    toolStatus: { searchResults: toolStatusList },
  } = useStoreState((state) => state);
  const { setReceiveTransferModalAction, setToolsInputAction } =
    useStoreActions((actions) => actions);

  return (
    <CustomModal
      onClose={() => {
        setReceiveTransferModalAction(false);
      }}
      isVisible={showReceiveTransferModal}
      title='Receive Transfer'
      style={{ width: 800 }}
      width='80%'
      height={Platform.OS == 'web' ? '50%' : '64%'}
    >
      <Row>
        <Col>
          <Input
            placeholder='Type in Storage Location'
            label='Storage Location'
            value={
              searchResults.find(
                (location) => location.id === input.storageLocationId
              )?.name || ''
            }
            onSelect={(storageLocation) =>
              setToolsInputAction({
                storageLocationId: storageLocation.id,
              })
            }
            selectedItem={(item) => item.name}
            rowTextForSelection={(item) => item.name}
            dropdown
            dropdownChoices={searchResults
              .sort((a, b) => a.name.localeCompare(b.name))
              .filter((item) => {
                return (
                  item.active !== false &&
                  input.tenantLocation === item.tenantlocationid
                );
              })}
            editable
          />
        </Col>
        <Col>
          <Input
            editable
            placeholder='Type in Field Tool Status'
            label='Field Tool Status'
            value={input.statusDescription}
            onSelect={(toolStatus) =>
              setToolsInputAction({
                toolStatusCode: toolStatus.statusCode.trim(),
                statusDescription: toolStatus.statusDescription.trim(),
              })
            }
            selectedItem={(item) => item.statusDescription}
            rowTextForSelection={(item) => item.statusDescription}
            dropdown
            dropdownChoices={toolStatusList.filter((item) => {
              return item.statusDescription !== 'Unserviceable';
            })}
          />
        </Col>
      </Row>
      <Gap />
      <Button
        fullWidth
        disabled={!(input.statusDescription && input.storageLocationId)}
        onPress={async () => {
          onSubmit();
        }}
      >
        Receive
      </Button>
    </CustomModal>
  );
}
